import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Avatar,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  Paper,
  FormControl,
  Tabs,
  Tab
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { connect } from "react-redux";
import { loginIMSAdmin, signUp } from "../../app/store/actions/authActions";
import {
  Navigate,
  NavLink,
  Route,
  Routes,
  useNavigate,
  useParams
} from "react-router-dom";
import {
  centered_flex_box,
  MainInput,
  MainInputLabel,
  MainPassword,
  main_button,
  StyledInput,
  MainPhone,
  sec_button
} from "../../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Image, Space, Table, Button } from "antd";
import Logo from "../../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import ImageComponent from "../../app/components/ImageComponent";
import { getVendors } from "../../app/store/actions/imsAdminActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";

export const BrandsList = ({
  getVendors,
  user,
  vendors,
  warehouseId,
  isLoading
}) => {
  const navigate = useNavigate();
  const [error, setError] = React.useState(null);
  const [terms, setTerms] = React.useState(false);
  const currentLocale = Cookies.get("i18next") || "en";
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getVendors({ page: 1, limit: 10000 })
  }, [])

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
    },
    {
      title: 'Brand Name',
      dataIndex: 'brandName',
    },
    {
      title: 'Brand Email',
      dataIndex: 'email',
    },
    {
      title: 'Orders this month',
      dataIndex: 'monthOrders',
      defaultSortOrder: 'descend',
      sorter: (a, b) => { return a.monthOrders - b.monthOrders },
    },
    {
      title: 'Actions',
      render: (_, { _id }) => <Space>
        <Button onClick={() => navigate(`/ims/admin/brand/${_id}`)}>View Brand</Button>
      </Space>
    },
  ];

  const data = vendors?.docs?.map((row) => {
    return {
      _id: row._id,
      brandName: row.brandName,
      email: row.email,
      monthOrders: row.monthOrders,
    }
  })

  return (
    <div>
      <Box sx={{ width: "100%", px: 5 }}>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Brands</Typography>
          <IconButton onClick={() => navigate("/ims/admin/brands/create")} sx={{ color: "var(--secColor)", mx: 2 }}><AddCircleOutline /></IconButton>
        </Box>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          showSorterTooltip={{
            target: 'sorter-icon',
          }}
        />
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  vendors: state?.records?.vendors
});

const mapDispatchToProps = { getVendors };

export default connect(mapStateToProps, mapDispatchToProps)(BrandsList);
