import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ReceiptIcon from '@mui/icons-material/Receipt';
import InfoIcon from '@mui/icons-material/Info';
import ContactsIcon from '@mui/icons-material/Contacts';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import DraftsIcon from '@mui/icons-material/Drafts';
import BoltIcon from '@mui/icons-material/Bolt';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import { connect } from "react-redux";
import { logout } from '../store/actions/authActions';
import { useLocation, useNavigate } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Chip, Drawer, FormControl, Grid, InputAdornment, InputLabel, NativeSelect, Select, Tooltip } from '@mui/material';
import { MainInput, MainSearch, centered_flex_box, left_flex_box, right_flex_box } from './Styles';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import StarsIcon from '@mui/icons-material/Stars';
import Logo from "./logo.png"
import Logo2 from "./logo2.png"
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { AccountBalance, AccountCircleOutlined, AddCard, Analytics, ArrowForward, Close, ExpandMore, Favorite, Home, Inventory, LocalShipping, Message, MonetizationOn, MoveToInbox, Outbox, Payment, People, PostAdd, PriceCheck, QuestionAnswer, QuestionMark, Receipt, RequestQuote, Search, Store, Warehouse } from '@mui/icons-material';
import LoginModal from './LoginModal';
import ImageComponent from './ImageComponent';

function TopBar({ auth, logout }) {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
    const [search, setSearch] = useState(null);

    const user = auth?.user;
    const token = auth?.token || '';
    const header = token.split(' ')
    const role = header[0]
    const location = useLocation();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setDrawerOpen(true)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setDrawerOpen(false)
    };

    const handleLogin = () => {
        setAnchorEl(null);
        route("/login")
    };

    const handleLogout = () => {
        setAnchorEl(null);
        logout();
    };

    const handleHome = () => {
        setAnchorEl(null);
        if (role === "InventoryEmployee") {
            navigate("/ims/employee");
        } else {
            navigate("/");
        }
    };

    const handleProfile = () => {
        setAnchorEl(null);
        let path = "/" + role + "/profile";
        navigate(path);
    };

    const route = (path) => {
        setAnchorEl(null);
        navigate(path);
    }

    const menuItems = <>
        <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
            <MobileView><IconButton className='position-absolute top-0 end-0' onClick={handleClose}><Close /></IconButton></MobileView>
            <ImageComponent preview={false} src={Logo} height={100} width={140} />
        </Box>
        {/* < hr />
        <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/about")}><InfoIcon /><Typography marginX={2}>About Us</Typography></MenuItem>
        <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/contact")}><Message /><Typography marginX={2}>Contact Us</Typography></MenuItem>
        <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/faq")}><QuestionAnswer /><Typography marginX={2}>FAQs</Typography></MenuItem> */}
        <hr />
        {user && role === "InventoryAdmin" && <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/ims/admin/brands")}><Store /><Typography marginX={2}>Brands</Typography></MenuItem>}
        {user && role === "InventoryAdmin" && <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/ims/admin")}><Home /><Typography marginX={2}>Dashboard</Typography></MenuItem>}
        {user && role === "InventoryAdmin" && <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/ims/admin/orders")}><ReceiptIcon /><Typography marginX={2}>Orders</Typography></MenuItem>}
        {user && role === "InventoryAdmin" && <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/ims/admin/products")}><Inventory /><Typography marginX={2}>Products</Typography></MenuItem>}
        {user && role === "InventoryAdmin" && <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/ims/admin/inbounds")}><MoveToInbox /><Typography marginX={2}>Inbounds</Typography></MenuItem>}
        {user && role === "InventoryAdmin" && <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/ims/admin/outbounds/create")}><Outbox /><Typography marginX={2}>Create Outbound</Typography></MenuItem>}
        {user && role === "InventoryAdmin" && <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/ims/admin/warehouses")}><Warehouse /><Typography marginX={2}>Warehouses</Typography></MenuItem>}
        {user && role === "InventoryAdmin" && <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/ims/admin/employees")}><People /><Typography marginX={2}>Employees</Typography></MenuItem>}
        {/* {user && role === "InventoryEmployee" && <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/ims/employee/account")}><AccountCircle /><Typography marginX={2}>حسابي</Typography></MenuItem>} */}
        {user && role === "InventoryEmployee" && <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/ims/employee/orders")}><Inventory /><Typography marginX={2}>طلباتي</Typography></MenuItem>}
        {user && role === "InventoryEmployee" && <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/ims/employee/")}><Home /><Typography marginX={2}>الرئيسية</Typography></MenuItem>}
        {user && role === "InventoryAdmin" && <><hr /><MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/ims/admin/packing")}><LocalShipping /><Typography marginX={2}>Packing System</Typography></MenuItem></>}
        {user && role === "Finance" && <><MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/warehousing/finance")}><Home /><Typography marginX={2}>Home</Typography></MenuItem><hr /></>}
        {user && role === "Finance" && <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/warehousing/finance/orders")}><Inventory /><Typography marginX={2}>View Unpaid Orders</Typography></MenuItem>}
        {user && role === "Finance" && <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/warehousing/finance/transactions")}><Receipt /><Typography marginX={2}>View Transactions</Typography></MenuItem>}
        {user && role === "Finance" && <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/warehousing/finance/transactions/add")}><PostAdd /><Typography marginX={2}>Add Transactions</Typography></MenuItem>}
        {user && role === "Finance" && <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/warehousing/finance/payouts")}><AccountBalance /><Typography marginX={2}>View Payouts</Typography></MenuItem>}
        {user && role === "Finance" && <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/warehousing/finance/payouts/due")}><Payment /><Typography marginX={2}>View Due Payouts</Typography></MenuItem>}
        {user && role === "Finance" && <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/warehousing/finance/payouts/add")}><AddCard /><Typography marginX={2}>Add Payouts</Typography></MenuItem>}
        <hr />
        {user && role !== "InventoryEmployee" && <MenuItem onClick={handleLogout}><LogoutIcon color='error' /><Typography color='error' marginX={2}>Logout</Typography></MenuItem>}
        {user && role === "InventoryEmployee" && <MenuItem onClick={handleLogout}><LogoutIcon color='error' /><Typography color='error' marginX={2}>تسجيل خروج</Typography></MenuItem>}
        {!user && <MenuItem onClick={handleLogin} sx={{ color: "var(--secColor)" }}><LoginIcon /><Typography marginX={2}>Login</Typography></MenuItem>}

    </>


    return (
        <Box sx={{ flexGrow: 1, color: "var(--secColor)", paddingBottom: 9 }}>
            <BrowserView>
                <AppBar sx={{ marginBottom: 2 }} position="fixed" color='white'>
                    <Toolbar sx={{ margin: 1 }}>
                        <Grid container>
                            <Grid item xs={3} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                <IconButton onClick={handleMenu}><MenuIcon sx={{ color: "var(--secColor)" }} /></IconButton>
                                <ImageComponent preview={false} src={Logo} height="80px" onClick={handleHome} style={{ cursor: "pointer" }} />
                            </Grid>
                            <Grid item xs={6} sx={{ ...centered_flex_box }}>

                            </Grid>
                            <Grid item xs={3} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                {user && role === "InventoryAdmin" && <Chip label={user.status === "Accepted" ? "Live" : "Pending"} sx={{ color: user.status === "Accepted" ? "white !important" : "", backgroundColor: user.status === "Accepted" ? "green" : "" }} />}
                                {user && <IconButton onClick={() => route("/user/account")}><AccountCircle sx={{ color: "var(--secColor)" }} /></IconButton>}
                                {!user && <IconButton onClick={() => handleLogin()}><AccountCircleOutlined sx={{ color: "var(--secColor)" }} /></IconButton>}
                            </Grid>
                        </Grid>
                    </Toolbar >
                    <React.Fragment key={"left"}>
                        <Drawer
                            anchor={"left"}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <Box sx={{ width: "25vw", padding: 1 }}>
                                {menuItems}
                            </Box>
                        </Drawer>
                    </React.Fragment>
                </AppBar >
            </BrowserView>
            <MobileView>
                <AppBar sx={{ marginBottom: 2 }} position="fixed" color='primary'>
                    <Toolbar>
                        <Grid container>
                            <Grid item xs={2} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                <ImageComponent width={50} height={"auto"} preview={false} src={Logo2} onClick={handleHome} style={{ cursor: "pointer", padding: 5 }} />
                            </Grid>
                            <Grid item xs={6}>

                            </Grid>
                            <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                {!user && <IconButton onClick={() => handleLogin()}><AccountCircleOutlined sx={{ color: "var(--appBg)" }} /></IconButton>}
                                <IconButton onClick={handleMenu}><MenuIcon sx={{ color: "var(--appBg)" }} /></IconButton>
                            </Grid>
                        </Grid>
                    </Toolbar >
                    <React.Fragment key={"top"}>
                        <Drawer
                            anchor="top"
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <Box sx={{ padding: 2 }}>
                                {menuItems}
                            </Box>
                        </Drawer>
                    </React.Fragment>
                </AppBar >
            </MobileView>
        </Box>
    );
}

const mapStateToProps = (state) => ({
    auth: state?.auth,
    categories: state?.records?.categories
});

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);