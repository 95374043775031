import * as React from "react";
import {
  Typography,
  Box,
  IconButton,
  Grid,
  Button as MUIButton
} from "@mui/material";
import { connect } from "react-redux";
import {
  NavLink,
  Navigate,
  useNavigate,
} from "react-router-dom";
import {
  MainInput,
  centered_flex_box,
  left_flex_box,
  main_button,
} from "../../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Button, Image, Space, Table, Tag } from "antd";
import { getPayouts } from "../../app/store/actions/whFinanceActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import moment from "moment";

export const Payouts = ({
  getPayouts,
  user,
  transactions,
  payouts,
  isLoading
}) => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = React.useState(moment().startOf("month"))
  const [endDate, setEndDate] = React.useState(moment().endOf("month"))

  useEffect(() => {
    getPayouts({ startDate, endDate })
  }, [])

  const handleFilter = () => {
    getPayouts({ startDate, endDate });
  }

  const payoutColumns = [
    {
      title: 'Date Paid',
      dataIndex: 'date',
      sorter: (a, b) => { return moment(a.date).isBefore(b.date) ? -1 : 1 },
    },
    {
      title: 'Value',
      dataIndex: 'value',
      render: (_, { value }) => `${value} EGP`,
      sorter: (a, b) => { return a.value - b.value },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      filters: [
        {
          text: "Service Fee",
          value: "Fee"
        },
        {
          text: "Payout",
          value: "Payout"
        }
      ],
      onFilter: (value, record) => record.type === value,
      render: (_, { type }) => <Tag>{type}</Tag>
    },
    {
      title: 'Service',
      dataIndex: 'service',
      render: (_, { service }) => <Tag>{service}</Tag>
    },
    {
        title: 'Vendor',
        dataIndex: ['vendor', 'name'],
        filters: [
            ...new Map(
                payouts
                    ?.filter(({vendor}) => vendor !== null)
                    .map(({vendor}) => [
                        vendor?.name, // Use the vendor's name as the key
                        { text: vendor?.name, value: vendor?.name },
                    ])
            ).values(),
        ],
        filterSearch: true,
        onFilter: (value, record) => record.vendor.name.startsWith(value),
    },
  ];

  const payoutData = payouts?.map((row) => {
    return {
      _id: row._id,
      vendor: row.vendor,
      date: moment(row.createdAt).format("lll"),
      service: row.service,
      type: row.type,
      value: row.value
    }
  })

  return (
    <div>
      <Box sx={{ width: "100%", padding: 5, mt: 2 }}>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Payouts</Typography>
          <IconButton onClick={() => navigate("/warehousing/finance/payouts/add")} sx={{ color: "var(--secColor)", mx: 2 }}><AddCircleOutline /></IconButton>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", px: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography>From</Typography>
              <MainInput
                margin="normal"
                fullWidth
                type="date"
                value={startDate.format("YYYY-MM-DD")}
                onChange={({ target }) => setStartDate(moment(target.value))}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography>To</Typography>
              <MainInput
                margin="normal"
                fullWidth
                type="date"
                value={endDate.format("YYYY-MM-DD")}
                onChange={({ target }) => setEndDate(moment(target.value))}
              />
            </Grid>
            <Grid item xs={2} sx={{ ...left_flex_box, alignItems: "end" }}>
              <MUIButton sx={{ ...main_button, mb: 2 }} onClick={() => handleFilter()}>Filter</MUIButton>
            </Grid>
          </Grid>
        </Box>
        <Table
          columns={payoutColumns}
          dataSource={payoutData}
          loading={isLoading}
          showSorterTooltip={{
            target: 'sorter-icon',
          }}
        />
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  payouts: state?.records?.payouts
});

const mapDispatchToProps = { getPayouts };

export default connect(mapStateToProps, mapDispatchToProps)(Payouts);
