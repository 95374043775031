import {
  FETCH_RECORDS,
  FETCH_RECORDS_SUCCESS,
  FETCH_RECORDS_FAIL,
  WAIT,
  WAIT_SUCCESS,
  WAIT_FAIL
} from "./types";
import {
  delRequest,
  getRequest,
  postRequest,
  putRequest
} from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import { notification } from "antd";
import download from "downloadjs";

export const getTransactions = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.whFinance.transactions)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const addTransactions = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  postRequest(data, undefined, undefined, token, endpoints.whFinance.transactions)
    .then((response) => {
      const { data } = response;
      notification.success({ message: data.message, style: { marginTop: "10vh" } })
      dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
      navigate && navigate("/warehousing/finance/transactions");
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const editTransactions = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  putRequest(data, undefined, undefined, token, endpoints.whFinance.transactions)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const deleteTransactions = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  delRequest(data, undefined, token, endpoints.whFinance.transactions)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const getPayouts = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.whFinance.payouts)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const addPayouts = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  postRequest(data, undefined, undefined, token, endpoints.whFinance.payouts)
    .then((response) => {
      const { data } = response;
      notification.success({ message: data.message, style: { marginTop: "10vh" } })
      dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
      navigate && navigate("/warehousing/finance/payouts");
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const editPayouts = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  putRequest(data, undefined, undefined, token, endpoints.whFinance.payouts)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const deletePayouts = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  delRequest(data, undefined, token, endpoints.whFinance.payouts)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const getServiceFees = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.whFinance.serviceFees)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const addServiceFees = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  postRequest(data, undefined, undefined, token, endpoints.whFinance.serviceFees)
    .then((response) => {
      const { data } = response;
      dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
      navigate && navigate("/warehousing/finance/serviceFees");
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const editServiceFees = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  putRequest(data, undefined, undefined, token, endpoints.whFinance.serviceFees)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const deleteServiceFees = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  delRequest(data, undefined, token, endpoints.whFinance.serviceFees)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const getUnpaidOrders = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.whFinance.orders)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const getPayoutsDue = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.whFinance.payoutsDue)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};