import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Select,
  MenuItem,
  IconButton,
  TableContainer,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  FormControl,
  Tabs,
  Tab
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { connect } from "react-redux";
import { loginIMSAdmin, signUp } from "../../app/store/actions/authActions";
import {
  Navigate,
  NavLink,
  Route,
  Routes,
  useNavigate,
  useParams
} from "react-router-dom";
import {
  centered_flex_box,
  MainInput,
  MainInputLabel,
  MainPassword,
  main_button,
  StyledInput,
  MainPhone,
  sec_button
} from "../../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Image } from "antd";
import Logo from "../../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import ImageComponent from "../../app/components/ImageComponent";
import { getEmployees } from "../../app/store/actions/imsAdminActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import moment from "moment/moment";

export const EmployeesList = ({
  getEmployees,
  user,
  employees,
  isLoading
}) => {
  const navigate = useNavigate();
  const [error, setError] = React.useState(null);
  const [terms, setTerms] = React.useState(false);
  const currentLocale = Cookies.get("i18next") || "en";
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getEmployees({page: 1, limit: 100})
  }, [])

  const getHeaders = () => {
    return (
      <TableRow>
        <TableCell>ID</TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Warehouse</TableCell>
        <TableCell>Email / Username</TableCell>
      </TableRow>
    );
  };

  const getRows = () => {

    if(isLoading) {
        return (
            <TableRow key={1} sx={{ ...centered_flex_box, p: 2, "&:last-child td, &:last-child th": { border: 0 }}}  >
                <TableCell colSpan={4}><CircularProgress sx={{ color: "var(--secColor)" }} /></TableCell>
            </TableRow>
        )
    }

    const rows = employees?.docs?.map((row) => {return (
        <TableRow
          key={row._id}
          hover
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell onClick={() => navigate(`/ims/admin/employee/${row._id}`)} sx={{ cursor: "pointer" }} component="th" scope="row">{row._id}</TableCell>
          <TableCell>{row.name}</TableCell>
          <TableCell>{row.warehouse.name}</TableCell>
          <TableCell>{row.email}</TableCell>
        </TableRow>
    )})

    return rows
  };

  return (
    <div>
      <Box sx={{ width: "100%", padding: 5, mt: 2 }}>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
            <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Employees</Typography>
            <IconButton onClick={() => navigate("/ims/admin/employees/create")} sx={{ color: "var(--secColor)", mx: 2 }}><AddCircleOutline /></IconButton>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>{getHeaders()}</TableHead>
            <TableBody>{getRows()}</TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  employees: state?.records?.employees
});

const mapDispatchToProps = { getEmployees };

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesList);
