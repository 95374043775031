import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Badge, BadgeMark } from '@mui/material';
import { connect } from "react-redux";
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import Logo from "../../app/components/logo512.png";
import { centered_flex_box, main_button } from '../../app/components/Styles';
import { AdminPanelSettings, BadgeOutlined, Login, MonetizationOn, VerifiedUser } from '@mui/icons-material';
import ImageComponent from '../../app/components/ImageComponent';

export const AdminHome = ({ token }) => {

    const role = token?.split(" ")[0]
    const navigate = useNavigate()
    const eventId = useParams().id

    if(role === "InventoryAdmin"){
        return Navigate({to: "/ims/admin"})
    } else if(role === "InventoryEmployee") {
        return Navigate({to: "/ims/employee"})
    } else if(role === "Finance") {
        return Navigate({to: "/warehousing/finance"})
    }

    return (
        <Container component="main">
            <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                <ImageComponent preview={false} src={Logo} height="250px" width="250px" />
                {<Button sx={{ ...main_button, m: 1 }} onClick={() => navigate("/login")}><AdminPanelSettings /><Typography marginX={2}>Login Admin</Typography></Button>}
                {<Button sx={{ ...main_button, m: 1 }} onClick={() => navigate("/warehousing/finance/login")}><MonetizationOn /><Typography marginX={2}>Login Finance</Typography></Button>}
                {<Button sx={{ ...main_button, m: 1 }} onClick={() => navigate("/ims/employee/login")}><BadgeOutlined /><Typography marginX={2}>تسجيل دخول موظف</Typography></Button>}
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    token: state?.auth?.token
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AdminHome);