import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Card, Grid, CardContent, Divider, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { centered_flex_box, main_button } from '../../app/components/Styles';
import { useEffect } from 'react';
import { getVendors, getProducts, getStore, getWarehouses } from '../../app/store/actions/imsAdminActions';
import ImageComponent from '../../app/components/ImageComponent';
import { AccountCircle, ExpandMore, Inventory, People, Receipt, Store, Warehouse } from '@mui/icons-material';
import OrdersTable from '../../app/components/OrdersTable';
import InboundsTable from '../../app/components/InboundsTable';
import { Table, Tag, Space } from 'antd';
import moment from 'moment';

export const Brand = ({ getVendors, orders, stocks, vendor, transactions, payouts, isLoading }) => {

    const navigate = useNavigate()
    const { brandId } = useParams()

    useEffect(() => {
        getVendors({ vendorId: brandId })
    }, [])

    const productsColumns = [
        {
            title: 'ID',
            dataIndex: '_id',
        },
        {
            title: 'Image',
            dataIndex: 'image',
            render: (text) => <ImageComponent width={50} height={50} src={text} preview={true} />,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            filters: stocks?.map((product) => {
                return {
                    text: product.name,
                    value: product.name
                }
            }),
            filterSearch: true,
            onFilter: (value, record) => record.name.startsWith(value),
        },
        {
            title: 'Size',
            dataIndex: 'size',
            filters: [
                {
                    text: "XXS",
                    value: "XXS"
                },
                {
                    text: "XS",
                    value: "XS"
                },
                {
                    text: "S",
                    value: "S"
                },
                {
                    text: "M",
                    value: "M"
                },
                {
                    text: "L",
                    value: "L"
                },
                {
                    text: "XL",
                    value: "XL"
                },
                {
                    text: "XXL",
                    value: "XXL"
                }
            ],
            filterSearch: true,
            onFilter: (value, record) => record.size.startsWith(value),
        },
        {
            title: 'Color',
            dataIndex: 'color',
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            sorter: (a, b) => a.price - b.price,
            render: (price, _) => `${price} EGP`
        },
        {
            title: 'QTY On Hand',
            dataIndex: 'amount',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.amount - b.amount,
        },
    ];

    const productsData = stocks?.map((row) => {
        return {
            _id: row.product.sku || row.product.externalId,
            image: row.product.image,
            name: row.product.name,
            size: row.product.size,
            color: row.product.color,
            weight: row.product.weight,
            price: row.product.price,
            amount: row.amount,
        }
    })

    const transactionsColumns = [
        {
            title: 'Order ID',
            dataIndex: '_id',
        },
        {
            title: 'Order Tracking ID',
            dataIndex: ['order', 'shipbluTrackingId'],
        },
        {
            title: 'Status',
            dataIndex: ['order', 'status'],
            filters: [
                {
                    text: "New",
                    value: "New"
                },
                {
                    text: "Preparing",
                    value: "Preparing"
                },
                {
                    text: "Prepared",
                    value: "Prepared"
                },
                {
                    text: "Out for Delivery",
                    value: "Out for Delivery"
                },
                {
                    text: "Delivered",
                    value: "Delivered"
                },
                {
                    text: "Cancelled",
                    value: "Cancelled"
                },
                {
                    text: "Sold Out",
                    value: "Sold Out"
                },
                {
                    text: "Invalid",
                    value: "Invalid"
                }
            ],
            onFilter: (value, record) => record.order.status === value,
            render: (_, { order }) => <Tag color={order.status === "Delivered" ? "green" : order.status === "Cancelled" ? "red" : order.status === "Prepared" ? "blue" : "yellow"}>{order.status.toUpperCase()}</Tag>
        },
        {
            title: 'Actions',
            render: (_, { order }) => <Space>
                <Button onClick={() => navigate(`/ims/admin/order/${order._id}`)}>View Order</Button>
            </Space>
        },
    ];

    const transactionsData = transactions?.map((row) => {
        return {
            _id: row.order._id,
            order: row.order,
            transactions: row.transactions,
        }
    })

    const expandedRowRender = (items) => {
        const columns = [
            {
                title: 'Date Created',
                dataIndex: 'date',
                sorter: (a, b) => { return moment(a.date).isBefore(b.date) ? -1 : 1 },
            },
            {
                title: 'Value',
                dataIndex: 'value',
                render: (_, { value }) => `${value} EGP`,
                sorter: (a, b) => { return a.value - b.value },
            },
            {
                title: 'Type',
                dataIndex: 'type',
                filters: [
                    {
                        text: "COD",
                        value: "COD"
                    },
                    {
                        text: "Service Fee",
                        value: "Fee"
                    },
                    {
                        text: "Shipping Fee",
                        value: "ShippingFee"
                    },
                    {
                        text: "Hold",
                        value: "Hold"
                    },
                    {
                        text: "Payout",
                        value: "Payout"
                    }
                ],
                onFilter: (value, record) => record.type === value,
                render: (_, { type }) => <Tag>{type}</Tag>
            },
            {
                title: 'Service',
                dataIndex: 'service',
                filters: [
                    {
                        text: "RTO",
                        value: "RTO"
                    },
                    {
                        text: "RTN",
                        value: "RTN"
                    },
                    {
                        text: "EXG",
                        value: "EXG"
                    },
                    {
                        text: "DLV",
                        value: "DLV"
                    },
                    {
                        text: "TRN",
                        value: "TRN"
                    },
                    {
                        text: "NONE",
                        value: "NONE"
                    }
                ],
                onFilter: (value, record) => record.service === value,
                render: (_, { service }) => <Tag>{service}</Tag>
            },
        ];

        const data = items?.map((row) => {
            return {
                _id: row._id,
                date: moment(row.createdAt).format("lll"),
                value: row.value,
                type: row.type,
                service: row.service,
            }
        })
        return <Table columns={columns} dataSource={data} pagination={false} />;
    };

    const payoutColumns = [
        {
            title: 'Date Paid',
            dataIndex: 'date',
            sorter: (a, b) => { return moment(a.date).isBefore(b.date) ? -1 : 1 },
        },
        {
            title: 'Value',
            dataIndex: 'value',
            render: (_, { value }) => `${value} EGP`,
            sorter: (a, b) => { return a.value - b.value },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            filters: [
                {
                    text: "Service Fee",
                    value: "Fee"
                },
                {
                    text: "Payout",
                    value: "Payout"
                }
            ],
            onFilter: (value, record) => record.type === value,
            render: (_, { type }) => <Tag>{type}</Tag>
        },
        {
            title: 'Service',
            dataIndex: 'service',
            render: (_, { service }) => <Tag>{service}</Tag>
        },
    ];

    const payoutData = payouts?.map((row) => {
        return {
            _id: row._id,
            date: moment(row.createdAt).format("lll"),
            service: row.service,
            type: row.type,
            value: row.value
        }
    })

    return (
        <div>
            <Box sx={{ ...centered_flex_box, flexDirection: "column", mt: 10, width: "100%" }}>
                <Typography variant='h4' sx={{ color: "var(--secColor)" }}>{vendor?.brandName} Details</Typography>
                <Accordion sx={{ width: "80%" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                    ><Typography fontWeight={"bold"} textAlign={"center"}>Stocks</Typography></AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ ...centered_flex_box, flexDirection: "column", width: "100%" }}>
                            <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Stocks</Typography>
                            <Table dataSource={productsData} columns={productsColumns} loading={isLoading} />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{ width: "80%" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                    ><Typography fontWeight={"bold"} textAlign={"center"}>Orders</Typography></AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ ...centered_flex_box, flexDirection: "column", width: "100%" }}>
                            <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Orders</Typography>
                            {Array.isArray(orders) && <OrdersTable orders={orders} />}
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{ width: "80%" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                    ><Typography fontWeight={"bold"} textAlign={"center"}>Transactions</Typography></AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ ...centered_flex_box, flexDirection: "column", width: "100%" }}>
                            <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Transactions</Typography>
                            <Table
                                columns={transactionsColumns}
                                dataSource={transactionsData}
                                loading={isLoading}
                                showSorterTooltip={{
                                    target: 'sorter-icon',
                                }}
                                rowKey={"_id"}
                                expandable={{
                                    expandedRowRender: (record) => expandedRowRender(record.transactions)
                                }}
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{ width: "80%" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                    ><Typography fontWeight={"bold"} textAlign={"center"}>Payouts</Typography></AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ ...centered_flex_box, flexDirection: "column", width: "100%" }}>
                            <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Payouts</Typography>
                            <Table
                                columns={payoutColumns}
                                dataSource={payoutData}
                                loading={isLoading}
                                showSorterTooltip={{
                                    target: 'sorter-icon',
                                }}
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </div>
    );
}

const mapStateToProps = (state) => ({
    store: state?.records?.store,
    warehouses: state?.records?.warehouses,
    vendor: state?.records?.vendor,
    transactions: state?.records?.transactions,
    payouts: state?.records?.payouts,
    orders: state?.records?.orders,
    stocks: state?.records?.stocks
});

const mapDispatchToProps = { getVendors, getProducts };

export default connect(mapStateToProps, mapDispatchToProps)(Brand);