import * as React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText
} from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sec_button } from "./Styles";
import { LoginIMSAdmin } from "../../Pages";

function LoginModal({ open, handleAccept, handleClose, title, content }) {

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{color: "var(--appBg)"}}
    >
      <LoginIMSAdmin close={handleClose}/>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
