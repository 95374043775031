import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, Tabs, Tab } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import { loginIMSAdmin, signUp } from '../../app/store/actions/authActions';
import { Navigate, NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, main_button, StyledInput, MainPhone, sec_button } from '../../app/components/Styles';
import { isMobile } from 'react-device-detect';
import { Image } from 'antd';
import Logo from "../../app/components/logo.png"
import PropTypes from 'prop-types';
import MuiPhoneNumber from 'material-ui-phone-number';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import ImageComponent from '../../app/components/ImageComponent';

export const LoginIMSAdmin = ({ loginIMSAdmin, user, token, signUp, isLoading, close }) => {


    const navigate = useNavigate();
    const [error, setError] = React.useState(null);
    const [terms, setTerms] = React.useState(false);
    const currentLocale = Cookies.get("i18next") || "en";
    const { t, i18n } = useTranslation();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var loginData = {
            login: data.get('login'),
            password: data.get('password')
        }

        loginIMSAdmin(loginData);
    };

    if (isLoading) {
        return (
            <Container maxWidth="xs">
                <Box sx={{ ...centered_flex_box, minHeight: isMobile ? "100%" : "100vh", minWidth: "100%" }}>
                    <CircularProgress sx={{ color: "var(--secColor)" }} />
                </Box>
            </Container>
        )
    }

    if (user) {
        try {
            window.ReactNativeWebView.postMessage(token);
        } catch (err) {
            console.log(err)
        }
        const role = token.split(" ")[0]
        if (role === "InventoryAdmin") {
            navigate("/ims/admin")
        } else if (role === "InventoryEmployee") {
            navigate("/ims/employee")
        } else if (role === "Finance") {
            navigate("/warehousing/finance")
        } else {
            navigate("/")
        }
    }

    return (
        <Container maxWidth="xs">
            <Box
                sx={{
                    paddingTop: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <ImageComponent preview={false} src={Logo} height="162px" width="227px" />
                <Box sx={{ width: '100%', mt: 2 }}>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <MainInput
                            margin="normal"
                            required
                            fullWidth
                            id="login"
                            label="Email / Mobile"
                            name="login"
                            autoComplete="email"
                            autoFocus
                        />
                        <MainPassword
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, ...main_button }}
                        >
                            Login
                        </Button>
                        {/* <Button onClick={() => navigate("/register")} fullWidth sx={{ color: "var(--secColor)" }}>
                            New to CHAINZ?
                        </Button> */}
                    </Box>
                </Box>
            </Box >
        </Container >
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.auth?.isLoading || state?.wait?.isLoading,
    token: state?.auth?.token
});

const mapDispatchToProps = { loginIMSAdmin, signUp };

export default connect(mapStateToProps, mapDispatchToProps)(LoginIMSAdmin);