import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, Tabs, Tab, Card, CardHeader, CardMedia, IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import { loginIMSAdmin, signUp } from '../../app/store/actions/authActions';
import { Navigate, NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, main_button, StyledInput, MainPhone, sec_button } from '../../app/components/Styles';
import { isMobile } from 'react-device-detect';
import { Image, notification } from 'antd';
import Logo from "../../app/components/logo.png"
import PropTypes from 'prop-types';
import MuiPhoneNumber from 'material-ui-phone-number';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import ImageComponent from '../../app/components/ImageComponent';
import { addProducts, getWarehouses } from '../../app/store/actions/imsAdminActions';
import ReactFileReader from "react-file-reader";
import { Close, Download, Upload } from '@mui/icons-material';
import { v4 as uuid } from 'uuid';
import { uploadFile } from "../../core/aws";
import { config } from '../../app/config';
import { useEffect } from 'react';
import Papa, { parse } from "papaparse";
import download from 'downloadjs';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    const navigate = useNavigate();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            autoCapitalize="false"
            {...other}
        >
            {value === index && (
                { ...children }
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const AddProducts = ({ addProducts, getWarehouses, warehouses, user, isLoading }) => {

    const navigate = useNavigate();
    const [stock, setStock] = React.useState(false);
    const [image, setImage] = React.useState(null);
    const [productsFile, setProductsFile] = React.useState(null);
    const [fetchingFile, setFetchingFile] = React.useState(false);
    const [uploadingFile, setUploadingFile] = React.useState(false);
    const currentLocale = Cookies.get("i18next") || "en";
    const { t, i18n } = useTranslation();

    const handleAddImage = (files) => {
        setFetchingFile(true);
        setImage(files[0]);
        setFetchingFile(false);
    };

    const handleDeleteImage = () => {
        setImage(null);
    };

    const handleAddFile = (files) => {
        setFetchingFile(true);
        setProductsFile(files[0]);
        setFetchingFile(false);
    };

    const handleDeleteFile = () => {
        setProductsFile(null);
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const directory = user?.store?.directory
        try {
            var imageData;

            setUploadingFile(true);
            if (image) {
                const fileSuffix = uuid();
                const filename = `${fileSuffix}_${image.name.replace(/\s+/g, '_')}`
                const params = {
                    Body: image,
                    Bucket: config.bucketName,
                    Key: `${directory}/${filename}`,
                    ACL: 'public-read'
                }

                imageData = await uploadFile(params);
            }
            setUploadingFile(false);
        } catch (err) {
            return console.log(err);
        }

        var products = [{
            name: data.get('name'),
            weight: data.get('weight'),
            size: data.get('size'),
            color: data.get('color'),
            externalId: data.get('externalId'),
            externalUrl: data.get('externalUrl'),
            amount: data.get('amount'),
            minAmount: data.get('minAmount'),
            image: imageData?.Location
        }]
        addProducts({ products, warehouseId: data.get('warehouseId') }, navigate);
    };

    const getShopifyOption = (option, product, headProduct) => {
        if(headProduct["Option1 Name"] === option) {
            return product["Option1 Value"]
        } else if(headProduct["Option2 Name"] === option) {
            return product["Option2 Value"]
        } else if(headProduct["Option3 Name"] === option) {
            return product["Option3 Value"]
        }
    }

    const handleSubmitMultiple = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var products = [];
        var reader = new FileReader();
        if (productsFile && data.get("fileSource") === "chainz") {
            reader.readAsText(productsFile);
            reader.onload = async () => {
                try {
                    const parsed = Papa.parse(reader.result, { header: true });
                    products = parsed.data.map((product) => {
                        return {
                            name: product.name,
                            weight: product.weight,
                            size: product.size,
                            color: product.color,
                            externalId: product.externalId,
                            externalUrl: product.externalUrl,
                            amount: product.amount,
                            minAmount: product.minAmount,
                            price: product.price,
                            image: product.image
                        }
                    });
                    products = products.filter((product) => product.name !== undefined)
                    addProducts({ products, warehouseId: data.get('warehouseId') }, navigate);
                } catch (err) {
                    console.log(err);
                }
            };
        } else if (productsFile && data.get("fileSource") === "shopify") {
            reader.readAsText(productsFile);
            reader.onload = async () => {
                try {
                    const parsed = Papa.parse(reader.result, { header: true });
                    let head = 0;
                    parsed.data.map((product, index) => {
                        if(product.Title !== "" && product["Variant Inventory Policy"] !== "") {
                            head = index;
                            products.push({
                                name: product.Title,
                                weight: getShopifyOption("Weight", product, product),
                                size: getShopifyOption("Size", product, product),
                                color: getShopifyOption("Color", product, product),
                                externalId: product["Variant SKU"] || product.Handle,
                                amount: parseInt(product["Variant Inventory Qty"]) || 0,
                                price: parseFloat(product["Variant Price"]) || 0,
                                image: product["Image Src"]
                            })
                        } else if (product["Variant Inventory Policy"] !== "") {
                            products.push({
                                name: parsed.data[head].Title,
                                weight: getShopifyOption("Weight", product, parsed.data[head]),
                                size: getShopifyOption("Size", product, parsed.data[head]),
                                color: getShopifyOption("Color", product, product),
                                externalId: product["Variant SKU"] || product.Handle,
                                amount: parseInt(product["Variant Inventory Qty"]) || 0,
                                price: parseFloat(product["Variant Price"]) || 0,
                                image: product["Image Src"]
                            })
                        }
                    });
                    products = products.filter((product) => (product.name !== undefined && !isNaN(product.amount)))
                    addProducts({ products, warehouseId: data.get('warehouseId') }, navigate);
                } catch (err) {
                    console.log(err);
                }
            };
        } else {
            notification.error({ message: "Please Upload a valid .csv file" });
        }
    };


    useEffect(() => {
        getWarehouses({ page: 1, limit: 100 })
    }, [])

    if (isLoading) {
        return (
            <Container maxWidth="xs">
                <Box sx={{ ...centered_flex_box, minHeight: isMobile ? "100%" : "100vh", minWidth: "100%" }}>
                    <CircularProgress sx={{ color: "var(--secColor)" }} />
                </Box>
            </Container>
        )
    }

    return (
        <Container maxWidth="xs">
            <Box
                sx={{
                    paddingTop: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Box sx={{ width: '100%', mt: 2 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Typography mb={2} variant='h4' textAlign={"center"} sx={{ color: "var(--secColor)" }}>Add Products</Typography>
                        <Tabs textColor='primary.main' value={value} centered onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Single" sx={{ mx: 3, fontWeight: "bold" }} {...a11yProps(0)} />
                            <Tab label="Multiple" sx={{ mx: 3, fontWeight: "bold" }} {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Box sx={{ width: '100%', mt: 2 }}>
                            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                <MainInput
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Product Name"
                                    name="name"
                                    autoFocus
                                />
                                <ReactFileReader
                                    multipleFiles={false}
                                    handleFiles={handleAddImage}
                                    fileTypes={["image/*"]}
                                >
                                    <Button fullWidth sx={{ my: 1, ...main_button }}>
                                        {!fetchingFile ? <>
                                            <Upload /> Upload Product Image
                                        </> : <>
                                            <CircularProgress />
                                        </>}
                                    </Button>
                                </ReactFileReader>
                                {image && (
                                    <Card sx={{ p: 2, ...centered_flex_box, flexDirection: "column" }}>
                                        <CardHeader
                                            title={image.name}
                                            titleTypographyProps={{ align: 'center' }}
                                            action={<IconButton onClick={handleDeleteImage} ><Close /></IconButton>}
                                        />
                                        <CardMedia>
                                            <ImageComponent width='100%' src={URL.createObjectURL(image)} alt={image.name} />
                                        </CardMedia>
                                    </Card>
                                )}
                                <Grid container spacing={2} sx={{ direction: "row" }}>
                                    <Grid item xs={4}>
                                        <MainInput
                                            margin="normal"
                                            fullWidth
                                            type="number"
                                            id="weight"
                                            label="Weight"
                                            name="weight"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <MainInput
                                            margin="normal"
                                            fullWidth
                                            id="size"
                                            label="Size"
                                            name="size"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <MainInput
                                            margin="normal"
                                            fullWidth
                                            id="color"
                                            label="Color"
                                            name="color"
                                        />
                                    </Grid>
                                </Grid>
                                <MainInput
                                    margin="normal"
                                    fullWidth
                                    id="externalId"
                                    label="External Store Id"
                                    name="externalId"
                                    autoFocus
                                />
                                <MainInput
                                    margin="normal"
                                    fullWidth
                                    id="externalUrl"
                                    label="External Store Link"
                                    name="externalUrl"
                                    autoFocus
                                />
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => { setStock(!stock) }}
                                    sx={{ mt: 3, mb: 2, ...main_button }}
                                >
                                    {stock ? "Add Later" : "Add Product Stock"}
                                </Button>
                                <hr />
                                {stock && <>
                                    <MainInput
                                        margin="normal"
                                        fullWidth
                                        required
                                        id="warehouseId"
                                        label="Warehouse"
                                        name="warehouseId"
                                        autoFocus
                                        select
                                    >
                                        {warehouses?.map((warehouse) => {
                                            return <MenuItem value={warehouse._id}>{warehouse.name}</MenuItem>
                                        })}
                                    </MainInput>
                                    <Grid container spacing={2} sx={{ direction: "row" }}>
                                        <Grid item xs={6}>
                                            <MainInput
                                                margin="normal"
                                                fullWidth
                                                required
                                                type="number"
                                                id="amount"
                                                label="Amount in stock"
                                                name="amount"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MainInput
                                                margin="normal"
                                                type="number"
                                                fullWidth
                                                id="minAmount"
                                                label="Minimum Amount"
                                                name="minAmount"
                                            />
                                        </Grid>
                                    </Grid>
                                    <hr />
                                </>}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2, ...main_button }}
                                >
                                    {!uploadingFile && !isLoading ? <>
                                        Add Product
                                    </> : <>
                                        <CircularProgress />
                                    </>}
                                </Button>
                            </Box>
                        </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Box component="form" onSubmit={handleSubmitMultiple} sx={{ mt: 1 }}>
                            <MainInput
                                margin="normal"
                                fullWidth
                                required
                                id="fileSource"
                                label="File Source"
                                name="fileSource"
                                autoFocus
                                select
                                defaultValue={"chainz"}
                            >
                                <MenuItem value={"chainz"}>CHAINZ</MenuItem>
                                <MenuItem value={"shopify"}>Shopify</MenuItem>
                            </MainInput>
                            <Button
                                fullWidth
                                sx={{ my: 1, ...sec_button }}
                                onClick={() =>
                                    download(
                                        "https://chainz-demo.s3.eu-west-3.amazonaws.com/Templates/product_list_sample.csv"
                                    )
                                }
                            >
                                <Download /> Chainz CSV Sample
                            </Button>
                            <ReactFileReader
                                multipleFiles={false}
                                handleFiles={handleAddFile}
                                fileTypes={[".csv"]}
                            >
                                <Button fullWidth sx={{ my: 1, ...main_button }}>
                                    {!fetchingFile ? <>
                                        <Upload /> Upload Product List (.csv)
                                    </> : <>
                                        <CircularProgress />
                                    </>}
                                </Button>
                            </ReactFileReader>
                            {productsFile && (
                                <Card sx={{ p: 2, ...centered_flex_box, flexDirection: "column" }}>
                                    <CardHeader
                                        title={productsFile.name}
                                        titleTypographyProps={{ align: 'center' }}
                                        action={<IconButton onClick={handleDeleteFile} ><Close /></IconButton>}
                                    />
                                </Card>
                            )}
                            <MainInput
                                margin="normal"
                                fullWidth
                                required
                                id="warehouseId"
                                label="Warehouse"
                                name="warehouseId"
                                autoFocus
                                select
                            >
                                {warehouses?.map((warehouse) => {
                                    return <MenuItem value={warehouse._id}>{warehouse.name}</MenuItem>
                                })}
                            </MainInput>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, ...main_button }}
                            >
                                {!uploadingFile && !isLoading ? <>
                                    Add Products
                                </> : <>
                                    <CircularProgress />
                                </>}
                            </Button>
                        </Box>
                    </CustomTabPanel>
                </Box>

            </Box >
        </Container >
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading,
    warehouses: state?.records?.warehouses?.docs
});

const mapDispatchToProps = { addProducts, getWarehouses };

export default connect(mapStateToProps, mapDispatchToProps)(AddProducts);