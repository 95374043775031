import * as React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Box
} from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MainInput, sec_button } from "./Styles";

function EditModal({ open, handleAccept, handleClose, title, item }) {

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ fontWeight: "bold" }} id="alert-dialog-title">
        {title}
      </DialogTitle>
      <Box component={"form"} onSubmit={handleAccept}>
        <DialogContent>
          <MainInput
            margin="normal"
            fullWidth
            required
            type="number"
            id="amount"
            label="Amount in stock"
            name="amount"
            defaultValue={item?.amount}
          />
        </DialogContent>
        <DialogActions>
          <Button sx={{ ...sec_button }} onClick={handleClose}>Cancel</Button>
          <Button sx={{ ...sec_button }} type="submit">
            Continue
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditModal);
