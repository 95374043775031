import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  Paper,
  FormControl,
  Tabs,
  Tab,
  Card,
  CardContent,
  Tooltip
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { connect } from "react-redux";
import { loginIMSAdmin, signUp } from "../../app/store/actions/authActions";
import {
  Navigate,
  NavLink,
  Route,
  Routes,
  useNavigate,
  useParams
} from "react-router-dom";
import {
  centered_flex_box,
  MainInput,
  MainInputLabel,
  MainPassword,
  main_button,
  StyledInput,
  MainPhone,
  sec_button,
  left_flex_box
} from "../../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Image, Table } from "antd";
import Logo from "../../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import ImageComponent from "../../app/components/ImageComponent";
import { getProducts, getWarehouses, downloadInboundsQRS } from "../../app/store/actions/imsAdminActions";
import { useEffect } from "react";
import { AddCircleOutline, DownloadOutlined } from "@mui/icons-material";
import moment from "moment/moment";
import InboundsTable from "../../app/components/InboundsTable";

export const InboundsList = ({
  getProducts,
  getWarehouses,
  downloadInboundsQRS,
  user,
  warehouses,
  products,
  isLoading
}) => {
  const navigate = useNavigate();
  const [selectedInboundItems, setSelectedInboundItems] = React.useState([]);
  const [copies, setCopies] = React.useState(1);
  const [warehouseId, setWarehouseId] = React.useState(null);
  const [terms, setTerms] = React.useState(false);
  const currentLocale = Cookies.get("i18next") || "en";
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getProducts({ page: 1, limit: 100, hasInbounds: true })
    getWarehouses({ page: 1, limit: 100 })
  }, [])

  useEffect(() => {
    getProducts({ page: 1, limit: 100, hasInbounds: true, warehouseId })
  }, [warehouseId])

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedInboundItems(newSelectedRowKeys.map((key) => { return key }));
  };

  const rowSelection = {
    selectedInboundItems,
    onChange: onSelectChange,
  };

  const handlePrintSelected = () => {
    const data = products?.filter((row) => { return selectedInboundItems.includes(row._id) }).map((row) => {
      return {
        inbound: row.inbound,
        inboundItem: {
          ...row,
          quantity: copies
        },
        product: row.product,
        vendor: row.inbound.vendor,
      }
    })

    downloadInboundsQRS({ items: data })
  }

  const handlePrintAllSelected = () => {
    const data = products?.filter((row) => { return selectedInboundItems.includes(row._id) }).map((row) => {
      return {
        inbound: row.inbound,
        inboundItem: row,
        product: row.product,
        vendor: row.inbound.vendor,
      }
    })

    downloadInboundsQRS({ items: data })
  }


  const handlePrintAllCopies = () => {
    const data = products?.map((row) => {
      return {
        inbound: row.inbound,
        inboundItem: {
          ...row,
          quantity: copies
        },
        product: row.product,
        vendor: row.inbound.vendor,
      }
    })

    downloadInboundsQRS({ items: data })
  }
  

  const handlePrintAll = () => {
    const data = products?.map((row) => {
      return {
        inbound: row.inbound,
        inboundItem: row,
        product: row.product,
        vendor: row.inbound.vendor,
      }
    })

    downloadInboundsQRS({ items: data })
  }


  return (
    <div>
      <Box sx={{ width: "100%", padding: 5, mt: 2 }}>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Inbounds</Typography>
          <Tooltip title="Download all pieces"><IconButton onClick={handlePrintAll} sx={{ color: "var(--secColor)", mx: 2 }}><DownloadOutlined /></IconButton></Tooltip>
        </Box>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Card sx={{ width: "100%" }}>
            <CardContent>
              <Box sx={{ ...left_flex_box }}>
                <MainInput
                  select
                  sx={{ marginInlineEnd: 2, minWidth: "20vw" }}
                  defaultValue={warehouseId}
                  label="Warehouse"
                  onChange={({ target }) => setWarehouseId((target.value))}
                >
                  {warehouses?.map((warehouse) => <MenuItem value={warehouse._id}>{warehouse.name}</MenuItem>)}
                </MainInput>
                <Button onClick={() => navigate(`/ims/admin/inbounds/receive/${warehouseId}`)} disabled={warehouseId === null || isLoading} sx={{ ...main_button }}>{isLoading ? <CircularProgress color="white" /> : "Receive Items"}</Button>
              </Box>
            </CardContent>
            <CardContent>
              <Box sx={{ ...left_flex_box }}>
                <MainInput
                  sx={{ marginInlineEnd: 2, minWidth: "20vw" }}
                  defaultValue={copies}
                  label="Copies to Print"
                  onChange={({ target }) => setCopies((target.value))}
                  type="number"
                  inputProps={{
                    min: 1
                  }}
                />
                <Button onClick={() => handlePrintSelected()} disabled={selectedInboundItems.length === 0 || isLoading} sx={{ ...main_button, mr: 1 }}>{isLoading ? <CircularProgress color="white" /> : `Print ${copies} copies of selected items`}</Button>
                <Button onClick={() => handlePrintAllCopies()} disabled={isLoading} sx={{ ...main_button, mr: 1 }}>{isLoading ? <CircularProgress color="white" /> : `Print ${copies} copies of all items`}</Button>
                <Button onClick={() => handlePrintAllSelected()} disabled={selectedInboundItems.length === 0 || isLoading} sx={{ ...main_button, mr: 1 }}>{isLoading ? <CircularProgress color="white" /> : `Print actual quantities of selected items`}</Button>
              </Box>
              <Typography sx={{ ml: 1 }}>{selectedInboundItems.length > 0 ? `Selected ${selectedInboundItems.length} items` : null}</Typography>
            </CardContent>
          </Card>
        </Box>
        <InboundsTable products={products?.docs ? [] : products} rowSelection={rowSelection} />
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  products: state?.records?.products,
  warehouses: state?.records?.warehouses?.docs
});

const mapDispatchToProps = { getProducts, getWarehouses, downloadInboundsQRS };

export default connect(mapStateToProps, mapDispatchToProps)(InboundsList);
