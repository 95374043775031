import { LOGIN, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS } from "../actions/types";

const initialState = {
    user: null,
    token: null,
    isLoading: false,
    isError: false,
};

export default function store(state = initialState, action) {
    const { type, payload, token } = action;
    switch (type) {
        case LOGIN:
            return {
                ...state,
                isLoading: true,
            };
        case LOGIN_SUCCESS:
            localStorage.setItem('ChainzIMSUser', JSON.stringify(payload));
            localStorage.setItem('ChainzIMSToken', token);
            return {
                ...state,
                user: payload,
                token: token,
                isLoading: false,
                isError: false,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        case LOGOUT_SUCCESS:
            localStorage.removeItem('ChainzIMSUser');
            localStorage.removeItem('ChainzIMSToken');
            return {
                ...initialState,
                isLoading: false,
                isError: false,
            };
        default:
            return state;
    }
}