import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Card, Grid, CardContent, Divider } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { centered_flex_box, main_button } from '../../app/components/Styles';
import { useEffect } from 'react';
import { getOrders, getProducts, getStore, getWarehouses } from '../../app/store/actions/imsAdminActions';
import ImageComponent from '../../app/components/ImageComponent';
import { AccountBalance, AccountCircle, AddCard, Inventory, Paid, Payment, People, PlusOne, PostAdd, Receipt, Store, Warehouse } from '@mui/icons-material';
import OrdersTable from '../../app/components/OrdersTable';
import InboundsTable from '../../app/components/InboundsTable';

export const WHFinanceHome = ({  }) => {

    const navigate = useNavigate()

    return (
        <div>
            <Box sx={{ ...centered_flex_box, flexDirection: "row" }}>
                {<Button sx={{ ...main_button, m: 1 }} onClick={() => navigate("/warehousing/finance/payouts")}><AccountBalance /><Typography marginX={2}>All Payouts</Typography></Button>}
                {<Button sx={{ ...main_button, m: 1 }} onClick={() => navigate("/warehousing/finance/payouts/due")}><Payment /><Typography marginX={2}>Due Payouts</Typography></Button>}
                {<Button sx={{ ...main_button, m: 1 }} onClick={() => navigate("/warehousing/finance/payouts/add")}><AddCard /><Typography marginX={2}>Add Payouts</Typography></Button>}
                {<Button sx={{ ...main_button, m: 1 }} onClick={() => navigate("/warehousing/finance/transactions")}><Receipt /><Typography marginX={2}>Transactions</Typography></Button>}
                {<Button sx={{ ...main_button, m: 1 }} onClick={() => navigate("/warehousing/finance/transactions/add")}><PostAdd /><Typography marginX={2}>Add Transactions</Typography></Button>}
            </Box>
            <Divider/>
            <Box  sx={{ ...centered_flex_box, flexDirection: "column", mt: 10, width: "100%" }}>
                <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Construction in progress</Typography>
                
            </Box>
        </div>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(WHFinanceHome);