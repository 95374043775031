import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, Tabs, Tab, Card, CardHeader, CardMedia, IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import { loginIMSAdmin, signUp } from '../../app/store/actions/authActions';
import { Navigate, NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, main_button, StyledInput, MainPhone, sec_button } from '../../app/components/Styles';
import { isMobile } from 'react-device-detect';
import { Image } from 'antd';
import Logo from "../../app/components/logo.png"
import PropTypes from 'prop-types';
import MuiPhoneNumber from 'material-ui-phone-number';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import ImageComponent from '../../app/components/ImageComponent';
import ReactFileReader from "react-file-reader";
import { Close, Upload } from '@mui/icons-material';
import { v4 as uuid } from 'uuid';
import { uploadFile } from "../../core/aws";
import { config } from '../../app/config';
import { useState } from 'react';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    const navigate = useNavigate();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            autoCapitalize="false"
            {...other}
        >
            {value === index && (
                { ...children }
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const RegisterIMS = ({ RegisterIMS, user, token, signUp, isLoading, close }) => {


    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [terms, setTerms] = useState(false);
    const [fetchingFile, setFetchingFile] = useState(false);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [currState, setCurrState] = useState(0)
    const currentLocale = Cookies.get("i18next") || "en";
    const { t, i18n } = useTranslation();

    const [logo, setLogo] = useState(null);
    const [AOC, setAOC] = useState(null);

    const handleAddLogo = (files) => {
        setFetchingFile(true);
        let file = files[0];
        setLogo(file);
        setFetchingFile(false);
    };

    const handleDeleteLogo = () => {
        setLogo(null);
    };

    const handleAddAOC = (files) => {
        setFetchingFile(true);
        let file = files[0];
        setAOC(file);
        setFetchingFile(false);
    };

    const handleDeleteAOC = () => {
        setAOC(null);
    };

    const handleSignupSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (data.get("password") !== data.get("password-confirm")) {
            setError("Passwords do not match")
        } else {
            const storeUUID = uuid();
            const directory = `BUSINESS_${storeUUID}`
            try {
                var logoData;
                var AOCData;

                setUploadingFile(true);
                if (logo) {
                    const fileSuffix = uuid();
                    const filename = `${fileSuffix}_${logo.name.replace(/\s+/g, '_')}`
                    const params = {
                        Body: logo,
                        Bucket: config.bucketName,
                        Key: `${directory}/${filename}`,
                        ACL: 'public-read'
                    }

                    logoData = await uploadFile(params);
                }
                if (AOC) {
                    const fileSuffix = uuid();
                    const filename = `${fileSuffix}_${AOC.name.replace(/\s+/g, '_')}`
                    const params = {
                        Body: AOC,
                        Bucket: config.bucketName,
                        Key: `${directory}/${filename}`,
                        ACL: 'public-read'
                    }

                    AOCData = await uploadFile(params);
                }
                setUploadingFile(false);
            } catch (err) {
                return console.log(err);
            }
            const info = {
                userData: {
                    name: data.get("firstName") + " " + data.get("lastName"),
                    email: data.get("email"),
                    mobile: data.get("mobile"),
                    password: data.get("password")
                },
                storeData: {
                    name: data.get("storeName"),
                    ownerNationalId: data.get("natId"),
                    type: data.get("type"),
                    taxID: data.get("taxId"),
                    articlesofIncorporationUpload: AOCData?.Location,
                    logo: logoData?.Location,
                    url: data.get("url"),
                    directory
                }
            }
            signUp(info, navigate);
        }
    };

    if (user) {
        try {
            window.ReactNativeWebView.postMessage(token);
        } catch (err) {
            console.log(err)
        }
        if (user.role === "InventoryAdmin") {
            navigate("/ims/admin")
        } else if (user.role === "InventoryEmployee") {
            navigate("/ims/employee")
        } else {
            navigate("/")
        }
    }

    if (isLoading) {
        return (
            <Container maxWidth="xs">
                <Box sx={{ ...centered_flex_box, minHeight: isMobile ? "100%" : "100vh", minWidth: "100%" }}>
                    <CircularProgress sx={{ color: "var(--secColor)" }} />
                </Box>
            </Container>
        )
    }

    return (
        <Container maxWidth="xs">
            <Box
                sx={{
                    paddingTop: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <ImageComponent preview={false} src={Logo} height="162px" width="227px" />
                <Box sx={{ width: '100%', mt: 2 }}>
                    <Box component="form" onSubmit={handleSignupSubmit} sx={{ mt: 1 }}>
                        <Typography sx={{ color: "var(--secColor)" }} fontWeight={"bold"} variant='h5' textAlign={"center"}>User Data</Typography>
                        <Grid container spacing={2} sx={{ direction: "row" }}>
                            <Grid item xs={6}>
                                <MainInput
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    name="firstName"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <MainInput
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                />
                            </Grid>
                        </Grid>
                        <MainInput
                            margin="normal"
                            type="email"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                        />
                        <MuiPhoneNumber
                            sx={{
                                "& label": {
                                    color: "var(--secColor) !important",
                                },
                                "& label.Mui-focused": {
                                    color: "var(--secColor) !important",
                                },
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        border: "1px solid var(--secColor)",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "var(--terColor)",
                                    },

                                    borderRadius: 0.5,
                                },
                            }}
                            margin="normal"
                            fullWidth
                            required
                            id="mobile"
                            label="Mobile Number"
                            name="mobile"
                            variant='outlined'
                            defaultCountry={'eg'}
                            preferredCountries={['eg']}
                            countryCodeEditable={false}
                            inputProps={{
                                minLength: 13
                            }}
                            disableAreaCodes
                        />
                        <MainPassword
                            margin="normal"
                            sx={{ my: 1 }}
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <MainPassword
                            margin="normal"
                            sx={{ my: 1 }}
                            required
                            fullWidth
                            name="password-confirm"
                            label="Confirm Password"
                            type="password"
                            id="password-confirm"
                            error={error}
                        />
                        {currState === 0 && <Button
                            fullWidth
                            variant="contained"
                            onClick={() => setCurrState(1)}
                            sx={{ mt: 3, mb: 2, ...main_button }}
                        >
                            Next
                        </Button>}
                        {currState === 1 && <>
                            <hr />
                            <Typography sx={{ color: "var(--secColor)" }} fontWeight={"bold"} variant='h5' textAlign={"center"}>Store Data</Typography>
                            <MainInput
                                margin="normal"
                                required
                                fullWidth
                                id="storeName"
                                label="Business Name"
                                name="storeName"
                            />
                            <MainInput
                                margin="normal"
                                required
                                fullWidth
                                id="natId"
                                label="Owner / Partner National ID"
                                name="natId"
                            />
                            <MainInput
                                margin="normal"
                                select
                                required
                                fullWidth
                                id="type"
                                label="Business Type"
                                name="type"
                            >
                                <MenuItem value="Individual">Individual</MenuItem>
                                <MenuItem value="Corporate">Corporate</MenuItem>
                            </MainInput>
                            <MainInput
                                margin="normal"
                                type="url"
                                fullWidth
                                id="url"
                                label="Business Website (ex. Shopify Store)"
                                name="url"
                            />
                            <MainInput
                                margin="normal"
                                fullWidth
                                id="taxId"
                                label="Business Tax ID"
                                name="taxId"
                            />
                            <ReactFileReader
                                multipleFiles={false}
                                handleFiles={handleAddLogo}
                                fileTypes={["image/*"]}
                            >
                                <Button fullWidth sx={{ my: 1, ...main_button }}>
                                    {!fetchingFile ? <>
                                        <Upload /> Upload Business Logo
                                    </> : <>
                                        <CircularProgress />
                                    </>}
                                </Button>
                            </ReactFileReader>
                            {logo && (
                                <Card sx={{ p: 2, ...centered_flex_box, flexDirection: "column" }}>
                                    <CardHeader
                                        title={logo.name}
                                        titleTypographyProps={{ align: 'center' }}
                                        action={<IconButton onClick={handleDeleteLogo} ><Close /></IconButton>}
                                    />
                                    <CardMedia>
                                        <ImageComponent src={URL.createObjectURL(logo)} alt={logo.name} />
                                    </CardMedia>
                                </Card>
                            )}
                            <ReactFileReader
                                multipleFiles={false}
                                handleFiles={handleAddAOC}
                                fileTypes={["image/*"]}
                            >
                                <Button fullWidth sx={{ my: 1, ...main_button }}>
                                    {!fetchingFile ? <>
                                        <Upload /> Upload First Page of "Articles of Incorporation" / "سجل تجاري"
                                    </> : <>
                                        <CircularProgress />
                                    </>}
                                </Button>
                            </ReactFileReader>
                            {AOC && (
                                <Card sx={{ p: 2, ...centered_flex_box, flexDirection: "column" }}>
                                    <CardHeader
                                        title={AOC.name}
                                        titleTypographyProps={{ align: 'center' }}
                                        action={<IconButton onClick={handleDeleteAOC} ><Close /></IconButton>}
                                    />
                                    <CardMedia>
                                        <ImageComponent src={URL.createObjectURL(AOC)} alt={AOC.name} />
                                    </CardMedia>
                                </Card>
                            )}
                            <Checkbox color='secondary' value={terms} onChange={() => setTerms(!terms)} />
                            <Button sx={{ ...sec_button }} href="/terms" target="_blank">
                                Read and Accept Terms & Conditions
                            </Button>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={!terms}
                                sx={{ mt: 3, mb: 2, ...main_button }}
                            >
                                {!uploadingFile && !isLoading ? <>
                                    Sign Up
                                </> : <>
                                    <CircularProgress />
                                </>}
                            </Button></>}
                    </Box>
                </Box>
            </Box >
        </Container >
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.auth?.isLoading || state?.wait?.isLoading,
    token: state?.auth?.token
});

const mapDispatchToProps = { signUp };

export default connect(mapStateToProps, mapDispatchToProps)(RegisterIMS);