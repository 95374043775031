import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, Tabs, Tab, Card, CardHeader, CardMedia, IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import { loginIMSAdmin, signUp } from '../../app/store/actions/authActions';
import { Navigate, NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, main_button, StyledInput, MainPhone, sec_button } from '../../app/components/Styles';
import { isMobile } from 'react-device-detect';
import { Image, notification } from 'antd';
import Logo from "../../app/components/logo.png"
import PropTypes from 'prop-types';
import MuiPhoneNumber from 'material-ui-phone-number';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import ImageComponent from '../../app/components/ImageComponent';
import { addEmployees, getWarehouses } from '../../app/store/actions/imsAdminActions';
import ReactFileReader from "react-file-reader";
import { Close, Download, Upload } from '@mui/icons-material';
import { v4 as uuid } from 'uuid';
import { uploadFile } from "../../core/aws";
import { config } from '../../app/config';
import { useEffect } from 'react';
import Papa, { parse } from "papaparse";
import download from 'downloadjs';

export const CreateEmployee = ({ addEmployees, getWarehouses, warehouses, user, isLoading }) => {

    const navigate = useNavigate();
    const [stock, setStock] = React.useState(false);
    const [image, setImage] = React.useState(null);
    const [productsFile, setProductsFile] = React.useState(null);
    const [fetchingFile, setFetchingFile] = React.useState(false);
    const [warehouseId, setWarehouseId] = React.useState(null);
    const currentLocale = Cookies.get("i18next") || "en";
    const { t, i18n } = useTranslation();


    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const directory = user?.store?.directory

        var order = {
            name: data.get('name'),
            email: data.get('email'),
            password: data.get('password'),
            warehouseId: data.get('warehouseId'),
        }
        addEmployees(order, navigate);
    };

    useEffect(() => {
        getWarehouses({ page: 1, limit: 100 })
    }, [])

    if (isLoading) {
        return (
            <Container maxWidth="xs">
                <Box sx={{ ...centered_flex_box, minHeight: isMobile ? "100%" : "100vh", minWidth: "100%" }}>
                    <CircularProgress sx={{ color: "var(--secColor)" }} />
                </Box>
            </Container>
        )
    }

    return (
        <Container maxWidth="xs">
            <Box
                sx={{
                    paddingTop: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Box sx={{ width: '100%', mt: 2 }}>
                    <Typography mt={2} variant='h4' textAlign={"center"} sx={{ color: "var(--secColor)" }}>Create Employee</Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <MainInput
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Employee Name"
                            name="name"
                            autoFocus
                        />
                        <MainInput
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Employee Email / Username"
                            name="email"
                            autoFocus
                        />
                        <MainPassword
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                        />
                        <MainInput
                            margin="normal"
                            fullWidth
                            required
                            id="warehouseId"
                            label="Warehouse"
                            name="warehouseId"
                            autoFocus
                            select
                            defaultValue={warehouseId}
                            onChange={({ target }) => { setWarehouseId(target.value) }}
                        >
                            {warehouses?.map((warehouse) => {
                                return <MenuItem value={warehouse._id}>{warehouse.name}</MenuItem>
                            })}
                        </MainInput>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, ...main_button }}
                        >
                            Create Employee
                        </Button>
                    </Box>
                </Box>
            </Box >
        </Container >
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading,
    warehouses: state?.records?.warehouses?.docs,
});

const mapDispatchToProps = { getWarehouses, addEmployees };

export default connect(mapStateToProps, mapDispatchToProps)(CreateEmployee);