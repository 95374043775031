import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Card, Grid, CardContent } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { centered_flex_box, main_button } from '../../app/components/Styles';
import { useEffect } from 'react';
import { } from '../../app/store/actions/imsAdminActions';
import ImageComponent from '../../app/components/ImageComponent';
import { getOrders, assignOrder } from '../../app/store/actions/imsEmployeesActions';
import moment from 'moment';
import { Inbox, Inventory, More, MoreHoriz } from '@mui/icons-material';

export const IMSEmployeeOrder = ({ user, getOrders, assignOrder, orders }) => {

    const navigate = useNavigate()
    const eventId = useParams().id
    const store = user?.store

    useEffect(() => {
        getOrders({ page: 1, limit: 100, mine: true })
    }, [])

    const startOrder = (orderId) => {
        assignOrder({ orderId }, navigate);
    }

    return (
        <div>
            <Box sx={{ ...centered_flex_box, m: 2, flexDirection: "column" }}>
                <Typography variant='h4' sx={{ color: "var(--secColor)" }}>طلباتي</Typography>
                {orders && orders.docs?.length > 0 && orders.docs.map((order) => {
                    return (
                        <Card sx={{ width: "100%", marginY: 1 }}>
                            <CardContent sx={{ ...centered_flex_box, flexDirection: "column", width: "100%" }}>
                                <Typography fontWeight={'bold'}>{order.status === "New" ? "طلب جديد" : (order.status === "Preparing" ? "طلب تحت التنفيذ" : "طلب جاهز")}</Typography>
                                {order.status === "New" ? <Typography>Created {moment(order.createdAt).fromNow()}</Typography> : order.status === "Prepared" ? <Typography>Completed in {moment(moment(order.preparedAt).diff(moment(order.assignedAt), 'seconds') * 1000).format("mm:ss")}</Typography> : <Typography>Assigned {moment(order.assignedAt).fromNow()}</Typography>}
                                <Typography>{order.externalId}</Typography>
                                {order.status === "New" && <Button sx={{ ...main_button }} onClick={() => startOrder(order._id)} ><Inventory sx={{ mx: 1 }} /> عين الطلب</Button>}
                                {order.status === "Preparing" && <Button sx={{ ...main_button }} onClick={() => navigate(`/ims/employee/order/${order._id}`)} ><Inventory sx={{ mx: 1 }} /> جهز الطلب</Button>}
                            </CardContent>
                        </Card>
                    )
                })}
                {orders && orders.docs?.length === 0 && <Box sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                    <Inbox sx={{ fontSize: 60 }} />
                    <Typography textAlign="center" fontSize={40}>لا يوجد طلبات</Typography>
                </Box>}
            </Box>
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    warehouses: state?.records?.warehouses,
    orders: state?.records?.orders
});

const mapDispatchToProps = { getOrders, assignOrder };

export default connect(mapStateToProps, mapDispatchToProps)(IMSEmployeeOrder);