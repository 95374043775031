import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, Tabs, Tab, Card, CardHeader, CardMedia, IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import { loginIMSAdmin, signUp } from '../../app/store/actions/authActions';
import { Navigate, NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, main_button, StyledInput, MainPhone, sec_button } from '../../app/components/Styles';
import { isMobile } from 'react-device-detect';
import { Image, notification } from 'antd';
import Logo from "../../app/components/logo.png"
import PropTypes from 'prop-types';
import MuiPhoneNumber from 'material-ui-phone-number';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import ImageComponent from '../../app/components/ImageComponent';
import { addVendors, getWarehouses } from '../../app/store/actions/imsAdminActions';
import ReactFileReader from "react-file-reader";
import { Close, Download, Upload } from '@mui/icons-material';
import { v4 as uuid } from 'uuid';
import { uploadFile } from "../../core/aws";
import { config } from '../../app/config';
import { useEffect } from 'react';
import Papa, { parse } from "papaparse";
import download from 'downloadjs';

export const CreateBrand = ({ addVendors, getWarehouses, warehouses, user, isLoading }) => {

    const navigate = useNavigate();
    const [addingBank, setAddingBank] = React.useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const directory = user?.store?.directory

        var order = {
            brandName: data.get('brandName'),
            name: data.get('name'),
            email: data.get('email'),
            ...(addingBank ? {
                bankDetails: {
                    accountName: data.get('accountName'),
                    swift: data.get('swift'),
                    iban: data.get('iban'),
                    bank: data.get('bank')
                }
            } : {})
        }
        addVendors(order, navigate);
    };

    useEffect(() => {
        getWarehouses({ page: 1, limit: 100 })
    }, [])

    if (isLoading) {
        return (
            <Container maxWidth="xs">
                <Box sx={{ ...centered_flex_box, minHeight: isMobile ? "100%" : "100vh", minWidth: "100%" }}>
                    <CircularProgress sx={{ color: "var(--secColor)" }} />
                </Box>
            </Container>
        )
    }

    return (
        <Container maxWidth="xs">
            <Box
                sx={{
                    paddingTop: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Box sx={{ width: '100%', mt: 2 }}>
                    <Typography mt={2} variant='h4' textAlign={"center"} sx={{ color: "var(--secColor)" }}>Create Brand</Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <MainInput
                            margin="normal"
                            required
                            fullWidth
                            id="brandName"
                            label="Brand Name"
                            name="brandName"
                            autoFocus
                        />
                        <MainInput
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Representative Name"
                            name="name"
                            autoFocus
                        />
                        <MainInput
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Representative Email"
                            name="email"
                            autoFocus
                        />
                        {addingBank && <>
                            <hr/>
                            <Typography mt={2} variant='h5' textAlign={"center"} sx={{ color: "var(--secColor)" }}>Brand Banking Details</Typography>
                            <MainInput
                                margin="normal"
                                required
                                fullWidth
                                id="bank"
                                label="Bank Name"
                                name="bank"
                                autoFocus
                            />
                            <MainInput
                                margin="normal"
                                required
                                fullWidth
                                id="swift"
                                label="Bank SWIFT Code"
                                name="swift"
                                autoFocus
                            />
                            <MainInput
                                margin="normal"
                                required
                                fullWidth
                                id="accountName"
                                label="Account Name"
                                name="accountName"
                                autoFocus
                            />
                            <MainInput
                                margin="normal"
                                required
                                fullWidth
                                id="iban"
                                label="IBAN"
                                name="iban"
                                autoFocus
                            />
                        </>}
                        {!addingBank && <Button
                            onClick={() => setAddingBank(true)}
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, ...sec_button }}
                        >
                            Add Bank Account
                        </Button>}
                        {addingBank && <Button
                            onClick={() => setAddingBank(false)}
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, ...sec_button }}
                        >
                            Remove Bank Account
                        </Button>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, ...main_button }}
                        >
                            Create Brand
                        </Button>
                    </Box>
                </Box>
            </Box >
        </Container >
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading,
    warehouses: state?.records?.warehouses?.docs,
});

const mapDispatchToProps = { getWarehouses, addVendors };

export default connect(mapStateToProps, mapDispatchToProps)(CreateBrand);