import * as React from "react";
import {
  Typography,
  Box,
  IconButton,
  Grid,
  Button as MUIButton
} from "@mui/material";
import { connect } from "react-redux";
import {
  NavLink,
  Navigate,
  useNavigate,
} from "react-router-dom";
import {
  MainInput,
  centered_flex_box,
  left_flex_box,
  main_button,
} from "../../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Button, Image, Space, Table, Tag } from "antd";
import { getPayoutsDue } from "../../app/store/actions/whFinanceActions";
import { useEffect } from "react";
import { AddCircleOutline, Download, DownloadOutlined } from "@mui/icons-material";
import moment from "moment";
import download from "downloadjs";

export const DuePayouts = ({
  getPayoutsDue,
  user,
  transactions,
  summary,
  isLoading
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    getPayoutsDue({})
  }, [])

  const downloadPayoutsCSV = () => {
    let content = "vendor_id,amount\n"
    summary.forEach(({vendor, totalDue}) => {
      if(totalDue > 0) {
        content = content + `${vendor._id},${totalDue}\n`
      }
    });
    download(content, `duePayouts_${moment().format("DD-MM-YYYY_HH_mm")}.csv`)
  }

  const payoutColumns = [
    {
      title: 'Vendor ID',
      dataIndex: "_id",
    },
    {
      title: 'Vendor',
      dataIndex: ['vendor', 'name'],
      filters: [
        ...new Map(
          summary
            ?.filter(({ vendor }) => vendor !== null)
            .map(({ vendor }) => [
              vendor?.name, // Use the vendor's name as the key
              { text: vendor?.name, value: vendor?.name },
            ])
        ).values(),
      ],
      filterSearch: true,
      onFilter: (value, record) => record.vendor.name.startsWith(value),
    },
    {
      title: 'Total Income',
      dataIndex: 'totalIncome',
      render: (_, { totalIncome }) => `${totalIncome} EGP`,
      sorter: (a, b) => { return a.totalIncome - b.totalIncome },
    },
    {
      title: 'Total Fees',
      dataIndex: 'totalFees',
      render: (_, { totalFees }) => `${totalFees} EGP`,
      sorter: (a, b) => { return a.totalFees - b.totalFees },
    },
    {
      title: 'Total On Hold',
      dataIndex: 'totalHold',
      render: (_, { totalHold }) => `${totalHold} EGP`,
      sorter: (a, b) => { return a.totalHold - b.totalHold },
    },
    {
      title: 'Total Paid',
      dataIndex: 'totalPayout',
      render: (_, { totalPayout }) => `${totalPayout} EGP`,
      sorter: (a, b) => { return a.totalPayout - b.totalPayout },
    },
    {
      title: 'Total Due',
      dataIndex: 'totalDue',
      defaultSortOrder: 'descend',
      render: (_, { totalDue }) => <Typography fontWeight={"bold"}>{totalDue} EGP</Typography>,
      sorter: (a, b) => { return a.totalDue - b.totalDue },
    },
  ];

  const payoutData = summary?.map((row) => {
    return {
      _id: row.vendor._id,
      vendor: row.vendor,
      totalIncome: row.totalIncome,
      totalFees: row.totalFees,
      totalHold: row.totalHold,
      totalPayout: row.totalPayout,
      totalDue: row.totalDue
    }
  })

  return (
    <div>
      <Box sx={{ width: "100%", padding: 5, mt: 2 }}>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Due Payouts</Typography>
          <IconButton onClick={() => navigate("/warehousing/finance/payouts/add")} sx={{ color: "var(--secColor)", mx: 2 }}><AddCircleOutline /></IconButton>
          <IconButton onClick={() => downloadPayoutsCSV()} sx={{ color: "var(--secColor)", mx: 2 }}><DownloadOutlined /></IconButton>
        </Box>
        <Table
          columns={payoutColumns}
          dataSource={payoutData}
          loading={isLoading}
          showSorterTooltip={{
            target: 'sorter-icon',
          }}
        />
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  summary: state?.records?.summary
});

const mapDispatchToProps = { getPayoutsDue };

export default connect(mapStateToProps, mapDispatchToProps)(DuePayouts);
