import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, Tabs, Tab, Card, CardHeader, CardMedia, IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import { loginIMSAdmin, signUp } from '../../app/store/actions/authActions';
import { Navigate, NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, main_button, StyledInput, MainPhone, sec_button, left_flex_box } from '../../app/components/Styles';
import { isMobile } from 'react-device-detect';
import { Image, notification } from 'antd';
import Logo from "../../app/components/logo.png"
import PropTypes from 'prop-types';
import MuiPhoneNumber from 'material-ui-phone-number';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import ImageComponent from '../../app/components/ImageComponent';
import { addOrders, getEmployees, getStocks, getWarehouses } from '../../app/store/actions/imsAdminActions';
import ReactFileReader from "react-file-reader";
import { Close, Delete, Download, Upload } from '@mui/icons-material';
import { v4 as uuid } from 'uuid';
import { uploadFile } from "../../core/aws";
import { config } from '../../app/config';
import { useEffect } from 'react';
import Papa, { parse } from "papaparse";
import download from 'downloadjs';

export const AddOrder = ({ addOrders, getWarehouses, warehouses, getEmployees, employees, getStocks, stocks, user, isLoading }) => {

    const navigate = useNavigate();
    const [stock, setStock] = React.useState(false);
    const [image, setImage] = React.useState(null);
    const [stockItems, setStockItems] = React.useState([]);
    const [warehouseId, setWarehouseId] = React.useState(null);
    const currentLocale = Cookies.get("i18next") || "en";
    const { t, i18n } = useTranslation();


    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var order = {
            externalId: data.get('externalId'),
            warehouseId: data.get('warehouseId'),
            assigneeId: data.get('assigneeId') === "" ? undefined : data.get('assigneeId'),
            stockItems
        }
        addOrders(order, navigate);
    };

    const addItem = ({ target }) => {
        setStockItems(prevItems => {
            let found = prevItems.find(item => item.stockItem === target.value._id);
            if (!found) {
                return [
                    ...prevItems,
                    {
                        stockItem: target.value._id,
                        stock: target.value,
                        quantity: 1
                    }
                ];
            }
            return prevItems;
        });
    };

    const editItem = (idx, { target }) => {
        setStockItems(prevItems => {
            let newItems = [...prevItems];
            newItems[idx].quantity = target.value;
            return newItems;
        });
    };

    const removeItem = (id) => {
        let currItems = stockItems.filter((item) => item.stockItem !== id)
        setStockItems(currItems)
    }

    useEffect(() => {
        getWarehouses({ page: 1, limit: 100 })
    }, [])

    useEffect(() => {
        console.log(warehouseId)
        if (warehouseId !== null) {
            getEmployees({ page: 1, limit: 100, warehouseId })
            getStocks({ page: 1, limit: 100, minAmount: 1, warehouseId })
        }
    }, [warehouseId])

    if (isLoading) {
        return (
            <Container maxWidth="xs">
                <Box sx={{ ...centered_flex_box, minHeight: isMobile ? "100%" : "100vh", minWidth: "100%" }}>
                    <CircularProgress sx={{ color: "var(--secColor)" }} />
                </Box>
            </Container>
        )
    }

    return (
        <Container maxWidth="xs">
            <Box
                sx={{
                    paddingTop: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Box sx={{ width: '100%', mt: 2 }}>
                    <Typography mt={2} variant='h4' textAlign={"center"} sx={{ color: "var(--secColor)" }}>Add Order</Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <MainInput
                            margin="normal"
                            required
                            fullWidth
                            id="externalId"
                            label="External Order ID"
                            name="externalId"
                            autoFocus
                        />
                        <MainInput
                            margin="normal"
                            fullWidth
                            required
                            id="warehouseId"
                            label="Warehouse"
                            name="warehouseId"
                            autoFocus
                            select
                            defaultValue={warehouseId}
                            onChange={({ target }) => { setWarehouseId(target.value) }}
                        >
                            {warehouses?.map((warehouse) => {
                                return <MenuItem value={warehouse._id}>{warehouse.name}</MenuItem>
                            })}
                        </MainInput>
                        {employees && <MainInput
                            margin="normal"
                            fullWidth
                            id="assigneeId"
                            label="Assignee"
                            name="assigneeId"
                            autoFocus
                            select
                        >
                            {employees?.map((employee) => {
                                return <MenuItem value={employee._id}>{employee.name}</MenuItem>
                            })}
                        </MainInput>}
                        {stocks && <MainInput
                            margin="normal"
                            fullWidth
                            label="Add Products"
                            autoFocus
                            onChange={addItem}
                            select
                        >
                            {stocks?.map((stock) => {
                                return <MenuItem value={stock}><Avatar sx={{ mr: 1 }} src={stock.product.image} />{stock.product.name} {stock.product.size && `(${stock.product.size})`} {stock.product.color && `(${stock.product.color})`} {stock.product.weight && `(${stock.product.weight})`} ({stock.amount})</MenuItem>
                            })}
                        </MainInput>}
                        <hr />
                        <Grid container spacing={2}>
                            {stockItems.map((stock, idx) => {
                                return (
                                    <>
                                        <Grid item xs={8} sx={{...left_flex_box}}>
                                            <Typography>{stock.stock.product.name} {stock.stock.product.size && `(${stock.stock.product.size})`} {stock.stock.product.color && `(${stock.stock.product.color})`} {stock.stock.product.weight && `(${stock.stock.product.weight})`}</Typography>
                                            {/* <Typography>{stock.stock.product.externalId}</Typography> */}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <MainInput
                                                margin="normal"
                                                required
                                                fullWidth
                                                label="QTY"
                                                type="number"
                                                inputProps={{ max: stock.stock.amount, min: 1 }}
                                                defaultValue={stock.quantity}
                                                onChange={(event) => editItem(idx, event)}
                                                autoFocus
                                            />
                                        </Grid>
                                        <Grid item xs={1} sx={{ ...centered_flex_box }}>
                                            <IconButton onClick={() => removeItem(stock.stockItem)}><Delete /></IconButton>
                                        </Grid>
                                    </>
                                )
                            })}
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, ...main_button }}
                        >
                            Add Order
                        </Button>
                    </Box>
                </Box>
            </Box >
        </Container >
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading,
    warehouses: state?.records?.warehouses?.docs,
    employees: state?.records?.employees?.docs,
    stocks: state?.records?.stocks?.docs
});

const mapDispatchToProps = { addOrders, getWarehouses, getEmployees, getStocks };

export default connect(mapStateToProps, mapDispatchToProps)(AddOrder);