import * as React from 'react';
import { Typography, Box, Dialog, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Card, Grid, CardContent } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { centered_flex_box, main_button } from '../../app/components/Styles';
import { useEffect } from 'react';
import { } from '../../app/store/actions/imsAdminActions';
import ImageComponent from '../../app/components/ImageComponent';
import { getOrders, packOrderItem, finalizeOrder } from '../../app/store/actions/imsEmployeesActions';
import moment from 'moment';
import { Inventory, More, MoreHoriz, QrCode } from '@mui/icons-material';
import { useState } from 'react';
import QrReader from "modern-react-qr-reader"

export const PackOrder = ({ user, getOrders, packItem, packOrderItem, finalizeOrder, order, orderItems }) => {

    const navigate = useNavigate()
    const orderId = useParams().orderId
    const [scanning, setScanning] = useState(false)
    const [elapsedTime, setElapsedTime] = useState(0);

    const handleScan = (data) => {
        if (data) {
            const json = JSON.parse(data)
            packOrderItem({ orderId, productId: json.productId }, navigate)
            setScanning(false);
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            const currentTime = order?.preparedAt ? moment(order?.preparedAt) : moment();
            const assignedTime = moment(order?.assignedAt);
            const elapsedSeconds = currentTime.diff(assignedTime, 'seconds');
            if (elapsedSeconds > 0) {
                setElapsedTime(elapsedSeconds);
            }
        }, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [order?.assignedAt]); // Add assignedAt to the dependency array

    useEffect(() => {
        getOrders({ orderId })
    }, [])

    useEffect(() => {
        if (!scanning) {
            getOrders({ orderId })
        }
    }, [scanning])

    return (
        <div>
            <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                <Typography fontWeight={'bold'}>Order {order?.externalId}</Typography>
                <Typography fontWeight={'bold'} variant="h4">{moment(elapsedTime * 1000).format("mm:ss")}</Typography>
                <Typography variant="h4">Store: {order?.vendor?.name}</Typography>

                <Box sx={{ p: 2 }}>
                    {orderItems && orderItems.length > 0 && orderItems.map((item) => {
                        return (
                            <Card key={item._id} sx={{ width: "100%", mb: 1 }}>
                                <CardContent sx={{ ...centered_flex_box, flexDirection: "column", width: "100%" }}>
                                    <Avatar src={item.stockItem.product.image} />
                                    <Typography fontWeight={"bold"} textAlign={"center"}>{item.stockItem.product.name}</Typography>
                                    <Typography>{item.stockItem.product.externalId}</Typography>
                                    <hr />
                                    {item.stockItem.product.size && <Typography>Size: {item.stockItem.product.size}</Typography>}
                                    {item.stockItem.product.weight && <Typography>Weight: {item.stockItem.product.weight}</Typography>}
                                    {item.stockItem.product.color && <Typography>Color: {item.stockItem.product.color}</Typography>}
                                    <hr />
                                    <Typography fontWeight={"bold"}>Quantity: {item.quantity}</Typography>
                                </CardContent>
                            </Card>
                        )
                    })}
                    <Typography>Items Left: {orderItems?.reduce((a, b) => a + b.quantity, 0)}</Typography>
                </Box>
                {!scanning && orderItems?.reduce((a, b) => a + b.quantity, 0) > 0 && <Button sx={{ ...main_button }} onClick={() => setScanning(true)}><QrCode sx={{ mx: 1 }} /> Scan Item</Button>}
                <Dialog
                    open={scanning}
                    onClose={() => setScanning(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{ color: "var(--appBg)" }}
                >
                    <Box sx={{ padding: 1 }}>
                        <QrReader
                            onScan={handleScan}
                            style={{
                                height: 300,
                                width: 300,
                            }}
                            facingMode={"environment"}
                        />
                    </Box>
                </Dialog>
            </Box>
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    warehouses: state?.records?.warehouses,
    orders: state?.records?.orders,
    order: state?.records?.order,
    orderItems: state?.records?.orderItems
});

const mapDispatchToProps = { getOrders, packOrderItem, finalizeOrder };

export default connect(mapStateToProps, mapDispatchToProps)(PackOrder);