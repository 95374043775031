import {
  FETCH_RECORDS,
  FETCH_RECORDS_SUCCESS,
  FETCH_RECORDS_FAIL
} from "./types";
import {
  delRequest,
  getRequest,
  postRequest,
  putRequest
} from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import { notification } from "antd";

export const getOrders = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.imsEmployee.orders)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const assignOrder = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  putRequest(data, undefined, undefined, token, endpoints.imsEmployee.assign)
    .then((response) => {
      const { data } = response;
      dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
      navigate && navigate(`/ims/employee/order/${data.order._id}`)
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const packOrderItem = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  putRequest(data, undefined, undefined, token, endpoints.imsEmployee.pack)
    .then((response) => {
      const { data, status } = response;
      notification.success({
        message: data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(status)
      if(status === 201) {
        navigate && navigate("/ims/employee")
      }
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const finalizeOrder = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  putRequest(data, undefined, undefined, token, endpoints.imsEmployee.finalize)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};
